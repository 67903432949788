export const PRIMARY = '#435179';
export const SECONDARY = '#B1BCDB';
export const LIGHT_PURPLE = '#e4e1f4';
export const PINK_BOLD = '#B40D81';
export const LINK_WATER = '#D1D5DB';

export const GRAY_PRIMARY = '#DEDEDE';
export const GRAY_SECONDARY = '#B4B4B4';
export const GRAY_THIRD = '#6E6E6E';
export const GRAY_LIGHT = '#F8F8F8';
export const PIGEON_POST = '#B1BCDB';
export const WHITE = '#FFFFFF';
export const WHITE_IMPORTANT = '#FFFFFF !important';

export const RED = '#ff0000';
export const ORANGE = '#FE5D26';
export const GREEN = '#46954D';
export const BLACK = '#000000';
export const RED_VIOLET = '#BE1F93';

export const STATUS_PENDING = '#FFD52C';
export const STATUS_INPROGRESS = '#5EC929';
export const STATUS_COMPLETED = '#B1BCDB';
